<template>
	<section class="gardenManagementPage">
		<g-breadcrumb bread="录入订单管理" />

		<div class="container">
			<div style="display: flex">
				<el-upload ref="upload" action="action"
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :show-file-list="false"
					:http-request="handlehttpRequest" :on-change="onUploadChange">
					<el-button size="small" type="primary" icon="el-icon-upload">导入Excel</el-button>
				</el-upload>
				

				<!-- <el-button style="margin-left: 20px" size="small" icon="el-icon-download" @click="handleDownloadJD">
					京东导出</el-button>
					<el-button style="margin-left: 20px" size="small" icon="el-icon-download" @click="handleDownloadTM">
						天猫导出</el-button> -->
				<!-- <a ref="handleDownload" style="display: none" href="./tradertemplate.xlsx"></a> -->
				<div>
					<el-button style="margin-left: 20px" size="small" icon="el-icon-refresh-left" @click="handleClear">
						更新
					</el-button>
				</div>
				<div style="margin-left: 450px;">
					<el-date-picker v-model="searchForm.startDay" type="date" placeholder="选择日期" format="yyyy/MM/dd "
						value-format="timestamp" :picker-options="pickerOptionsStart" @change="changeStart">
					</el-date-picker>
				</div>
				
				<div style="margin-left: auto;">
					<download-excel class="export-excel-wrapper" :data="json_data" name="京东订单.xlsx">
						<el-button @click="handleDownloadJD" type="primary">京东导出</el-button>
					</download-excel>
				</div>
				<div style="margin-left: 20px;">
					<download-excel class="export-excel-wrapper" :data="json_data1" name="天猫订单.xlsx">
						<el-button @click="handleDownloadTM" type="primary">天猫导出</el-button>
					</download-excel>
				</div>
				<div style="margin-left: 20px;">
					<download-excel class="export-excel-wrapper" :data="json_data2" name="微信小店订单.xlsx">
						<el-button @click="handleDownloadWX" type="primary">微信小店导出</el-button>
					</download-excel>
				</div>

			</div>
			<TableContainer title="录入订单管理">
				<template slot="button">
					<el-button type="success" @click="addGarden" class="turnBule">添加订单</el-button>
				</template>
				<el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
					:cell-style="{ textAlign: 'center' }">
					<!-- <el-table-column prop="appid" label="id" min-width="160" /> -->
					<el-table-column prop="tradeNo" label="订单号" min-width="100" />
					<el-table-column prop="customerName" label="客户昵称" min-width="100" />
					<el-table-column prop="receiverAddress" label="地址" min-width="100" />
					<el-table-column prop="receiverMobile" label="手机号" min-width="100" />
					<el-table-column prop="createTime" label="录入订单时间" min-width="100" />
					<el-table-column width="200" label="操作">
						<template slot-scope="scope">
							<el-button type="text" class="reviewed" @click="editGarden(scope.row)">编辑订单</el-button>
							<el-button type="text" class="textOrange" @click="editGarden1(scope.row)">删除订单</el-button>
						</template>
					</el-table-column>
				</el-table>
			</TableContainer>

			<GDialog :dialog.sync="themeDialog" @btnSure="submitThemeForm1('ref_themeForm')"
				@btnClose="resetThemeForm('ref_themeForm')">
				<el-form ref="ref_themeForm" :model="themeForm" :rules="rules" label-width="100px"
					@submit.native.prevent>
					<el-form-item label="订单号" prop="tradeNo">
						<el-input v-model="themeForm.tradeNo" maxlength="35" placeholder="请输入订单号" />
					</el-form-item>
					<el-form-item label="客户昵称" prop="customerName">
						<el-input v-model="themeForm.customerName" maxlength="35" placeholder="请输入客户昵称" />
					</el-form-item>
					<el-form-item label="地址" prop="receiverAddress">
						<el-input v-model="themeForm.receiverAddress" maxlength="35" placeholder="请输入地址" />
					</el-form-item>
					<el-form-item label="手机号" prop="receiverMobile">
						<el-input v-model="themeForm.receiverMobile" maxlength="35" placeholder="请输入手机号" />
					</el-form-item>
				</el-form>
			</GDialog>

			<GDialog :dialog.sync="themeDialog1" @btnSure="submitThemeForm2('ref_themeForm')"
				@btnClose="resetThemeForm('ref_themeForm')">
				<el-form ref="ref_themeForm" :model="themeForm" :rules="rules" label-width="100px"
					@submit.native.prevent>
					<el-form-item label="订单号" prop="tradeNo">
						<el-input v-model="themeForm.tradeNo" maxlength="35" placeholder="请输入订单号" />
					</el-form-item>
					<el-form-item label="客户昵称" prop="customerName">
						<el-input v-model="themeForm.customerName" maxlength="35" placeholder="请输入客户昵称" />
					</el-form-item>
					<el-form-item label="地址" prop="receiverAddress">
						<el-input v-model="themeForm.receiverAddress" maxlength="35" placeholder="请输入地址" />
					</el-form-item>
					<el-form-item label="手机号" prop="receiverMobile">
						<el-input v-model="themeForm.receiverMobile" maxlength="35" placeholder="请输入手机号" />
					</el-form-item>
				</el-form>
			</GDialog>
		</div>
	</section>


</template>

<script>
	import {
		importTrade,
		findImportTrade,
		updateTrade,
		downTrade,
		updateImportTrade,
		delImportTrade,
		addImportTrade,
		exportTbList,
		exportJdList,
		exportWxxdList,
	} from '@api/gardenManagement/api_gardenManagement'
	// import { tableData } from './data.js'

	export default {
		data() {
			return {
				file: null,
				tableData: [],
				json_data: [],
				json_data1: [],
				json_data2: [],
				pickerOptionsStart: {},
				// startDay: "",
				total: 0,
				searchForm: {
					startDay: "",
					page: 1,
					size: 10
				},
				themeDialog: {
					title: '编辑订单信息',
					visible: false
				},
				themeDialog1: {
					title: '新增订单信息',
					visible: false
				},
				themeForm: {
					tradeNo: '',
					customerName: '',
					receiverAddress: '',
					receiverMobile: ''
				},
				rules: {
					tradeNo: [{
						required: true,
						message: '请输入订单号',
						trigger: 'blur'
					}],
					customerName: [{
							required: true,
							message: '请输入客户昵称',
							trigger: 'blur'
						},
						// { min: 3, max: 35, message: '请输入用户性别', trigger: 'blur' }
					],
					receiverAddress: [{
							required: true,
							message: '请输入地址',
							trigger: 'blur'
						},
						// { min: 3, max: 11, message: '请输入用户手机号码', trigger: 'blur' }
					],
					receiverMobile: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						// { min: 3, max: 35, message: '请输入用户地址', trigger: 'blur' }
					]
				}

			}
		},


		methods: {
			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},
			handlehttpRequest() {},
			fetchData() {},
			changeStart() {
				console.log(this.startDay)
			},
			findImportTrade() {
				findImportTrade(this.searchForm).then(res => {
					console.log(res, 1111)
					this.tableData = res
					// this.total = res.total
				})
			},
			addGarden() {
				this.themeDialog1.visible = true;

			},

			// 读取文件为json数据
			onUploadChange(file) {
				console.log(file)
				let fileData = new FormData()
				fileData.append('file', file.raw)
				importTrade(fileData, 'noLoad').then((res) => {
					this.findImportTrade()
					//       if(res.code == 20000){
					// 	this.findImportTrade()
					// }
				})
			},
			// 编辑
			editGarden(row) {
				console.log(row, 99999)
				this.themeDialog.visible = true
				this.themeDialog.title = '编辑录入订单信息'
				this.themeForm = row
			},
			submitThemeForm2(formName) {
				this.$refs[formName].validate(valid => {
					console.log(valid, 1111)
					if (!valid) {

						return false
					}
					const params = {
						...this.themeForm
					}
					// let _api
					// params.materialId = Number(this.activeTabsName)
					// if (this.themeDialog.title === '添加主题') {
					// 	_api = addTheme
					// } else {
					// 	if (this.currentThemeRow.themeName === params.themeName) {
					// 		this.$message.error('主题名称未作修改！')
					// 		return false
					// 	}
					// 	_api = updateTheme
					// 	params.themeId = this.currentThemeRow.id
					// }
					addImportTrade(params).then(res => {
						this.$message.success(this.themeDialog.title + '成功')
						this.resetThemeForm('ref_themeForm')
						this.findImportTrade()
					})
				})
			},
			submitThemeForm1(formName) {
				console.log(formName, 2222)
				this.$refs[formName].validate(valid => {
					console.log(valid, 1111)
					if (!valid) {

						return false
					}
					const params = {
						...this.themeForm
					}
					// let _api
					// params.materialId = Number(this.activeTabsName)
					// if (this.themeDialog.title === '添加主题') {
					// 	_api = addTheme
					// } else {
					// 	if (this.currentThemeRow.themeName === params.themeName) {
					// 		this.$message.error('主题名称未作修改！')
					// 		return false
					// 	}
					// 	_api = updateTheme
					// 	params.themeId = this.currentThemeRow.id
					// }
					updateImportTrade(params).then(res => {
						this.$message.success(this.themeDialog.title + '成功')
						this.resetThemeForm('ref_themeForm')
						this.findImportTrade()
					})
				})
			},
			// 删除
			editGarden1(row) {
				this.$confirm('是否确认删除?', '提示', {
					type: 'warning',
					closeOnClickModal: false
				}).then(() => {
					delImportTrade({
						tradeId: row.tradeId,
						tradeNo: row.tradeNo
					}).then(res => {
						this.$message.success('删除成功')
						this.findImportTrade()
					})
				})
			},
			handleDownloadWX(){
				exportWxxdList(this.searchForm).then(res => {
					console.log(res)
					this.json_data2 = [];
					for (var j of res) {
						this.json_data2.push({
							"订单号": j.srcTids,
							"客户名称": "",
							"手机": "",
							// "客户昵称": i.customerName,
							"地址": "",



						})
					}
					// this.$rotuer.go(0);
					console.log(111)

				}).catch(err => {

				})
			},
			handleDownloadJD() {
				// this.$rotuer.go(0);
				exportJdList(this.searchForm).then(res => {
					console.log(res)
					// this.tableData4 = res;
					this.json_data = [];
					for (var j of res) {
						this.json_data.push({
							"订单号": j.srcTids,
							"客户名称": "",
							"手机": "",
							// "客户昵称": i.customerName,
							"地址": "",



						})
					}
					// this.$rotuer.go(0);
					console.log(111)

				}).catch(err => {

				})
			},
			handleDownloadTM() {
				exportTbList(this.searchForm).then(res => {
					console.log(res)
					// this.tableData4 = res;
					this.json_data1 = [];
					for (var i of res) {
						this.json_data1.push({
							"订单号": i.srcTids,
							"客户名称": "",
							"手机": "",
							// "客户昵称": i.customerName,
							"地址": "",



						})
					}

				}).catch(err => {

				})
			},

			handleClear() {
				updateTrade().then((res) => {
					console.log(res)
					this.tableData = res
					this.$router.push('/gardenManagement/wangDianTong')
					// if(res.code == 20000){
					//  this.tableData = res
					//  this.$router.push('/gardenManagement/wangDianTong')
					// }

				})
			},
			submitThemeForm() {
				this.themeDialog.visible = false
			},
			resetThemeForm(formName) {
				// this.$refs[formName].resetFields()
				this.themeDialog1.visible = false
			}
		},


		created() {
			this.findImportTrade();
		},
	}
</script>

<style scoped lang="scss">
	body {
		background: #f4f4f4;
		padding: 0;
		margin: 0;
	}

	.container {
		// width: 1024px;
		/* min-height: 100vh; */
		margin: 0 auto;
		// padding: 20px;
		background: #fff;
	}

	.turnBule {
		color: #fff;
		background-color: #58e3e8;
		border-color: rgba(56, 185, 190, 0.12);
	}

	.turnBule:hover,
	.turnBule:focus {
		background: rgba(56, 185, 190, 0.5);
		border-color: rgba(56, 185, 190, 0.5);
		color: #fff;
	}

	.reviewed {
		color: #38b9be;
	}
</style>
