import request from '@utils/fetch'
// 园所管理

// 查询客户列表
export function postFindMfDockor(data) {
  return request({
    url: '/mf/findMfDockor',
    method: 'post',
    data
  })
}

//查看客户下的用户列表
export function findMfDockorUser(data) {
  return request({
    url: '/mf/findMfDockorUser',
    method: 'post',
    data
  })
}

//查询客户的金额管理
export function findMfDockorMoney(data) {
  return request({
    url: '/mf/findMfDockorMoney',
    method: 'post',
    data
  })
}

//查询客户下用户的金额列表
export function findMfDockorUserMoney(data) {
  return request({
    url: '/mf/findMfDockorUserMoney',
    method: 'post',
    data
  })
}

// 查询客户下的所有用户的订单明细信息
export function findMfUserTradeByUserId(data) {
  return request({
    url: '/mf/findMfUserTradeByUserId',
    method: 'post',
    data
  })
}

// 查询订单的商品信息
export function findTradeGoods(data) {
  return request({
    url: '/trade/findTradeGoods',
    method: 'post',
    data
  })
}

//导入
export function importTrade(data) {
  return request({
    url: '/trade/importTrade',
    method: 'post',
    data
  })
}

//查询订单表
export function findImportTrade(data) {
  return request({
    url: '/trade/findImportTrade',
    method: 'post',
    data
  })
}

//更新
export function updateTrade(data) {
  return request({
    url: '/trade/updateTrade',
    method: 'post',
    data
  })
}

//查询代理商订单
export function selectAllTrade(data) {
  return request({
    url: '/trade/selectAllTrade',
    method: 'post',
    data
  })
}

//查询旺店通订单
export function selectTrade(data) {
  return request({
    url: '/trade/selectTrade',
    method: 'post',
    data
  })
}

//导出
export function downTrade(data) {
  return request({
    url: '/trade/downTrade',
    method: 'post',
    data
  })
}

//编辑录入订单信息
export function updateImportTrade(data) {
  return request({
    url: '/trade/updateImportTrade',
    method: 'post',
    data
  })
}

//删除录入订单信息
export function delImportTrade(data) {
  return request({
    url: '/trade/delImportTrade',
    method: 'post',
    data
  })
}

//匹配
export function updateUserStatus(data) {
  return request({
    url: '/trade/updateUserStatus',
    method: 'post',
    data
  })
}

//查询所有店铺
export function getDictoryInfoByCode(data) {
  return request({
    url: '/common/getDictoryInfoByCode?dicCode' + '=dp',
    method: 'post',
  })
}

//查看用户类型
export function getDictoryInfoByCode1(data) {
  return request({
    url: '/common/getDictoryInfoByCode?dicCode' + '=userType',
    method: 'post',
  })
}

// 查询未更新订单
export function selectnoUpdateTrade(data) {
  return request({
    url: '/trade/selectnoUpdateTrade',
    method: 'post',
    data
  })
}

//查询未审核订单
export function noBeReviewedTrade(data) {
  return request({
    url: '/trade/noBeReviewedTrade',
    method: 'post',
    data
  })
}

//新增录入订单信息
export function addImportTrade(data) {
  return request({
    url: '/trade/addImportTrade',
    method: 'post',
    data
  })
}

//导出匹配成功的订单
export function exportClassList(data) {
  return request({
    url: '/trade/exportClassList',
    method: 'post',
    data
  })
}

//查看敏肤用户
export function findDockorMfUser(data) {
  return request({
    url: '/mf/findDockorMfUser',
    method: 'post',
    data
  })
}

//查看系统用户
export function findDockorSysUser(data) {
  return request({
    url: '/mf/findDockorSysUser',
    method: 'post',
    data
  })
}

//查看客户管理下的用户
export function findMfDockorAllUser(data) {
  return request({
    url: '/mf/findMfDockorAllUser',
    method: 'post',
    data
  })
}

//导出原始订单
export function tradeImport(data) {
  return request({
    url: '/trade/tradeImport',
    method: 'post',
    data
  })
}

//查看待审核匹配用户
export function findAllUser(data) {
  return request({
    url: '/mf/findAllUser',
    method: 'post',
    data
  })
}

//查询总部订单
export function generalTrade(data) {
  return request({
    url: '/trade/generalTrade',
    method: 'post',
    data
  })
}

//批量匹配
export function batchUpdateUserStatus(data) {
  return request({
    url: '/trade/batchUpdateUserStatus',
    method: 'post',
    data
  })
}

//查看导入淘系订单管理
export function getTbTrade(data) {
  return request({
    url: '/trade/getTbTrade',
    method: 'post',
    data
  })
}

//导入
export function importTbTrade(data) {
  return request({
    url: '/trade/importTbTrade',
    method: 'post',
    data
  })
}

//更新导入淘系订单管理
export function updateTbTradeGoods(data) {
  return request({
    url: '/trade/updateTbTradeGoods',
    method: 'post',
    data
  })
}

//查看录入淘系订单
export function getTbTradeGoods(data) {
  return request({
    url: '/trade/getTbTradeGoods',
    method: 'post',
    data
  })
}

//导入录入淘系订单
export function importTbTradeGoods(data) {
  return request({
    url: '/trade/importTbTradeGoods',
    method: 'post',
    data
  })
}

//更新录入淘系订单
export function updateTbTrade(data) {
  return request({
    url: '/trade/updateTbTrade',
    method: 'post',
    data
  })
}

export function verifyWXToken(data) {
  return request({
    url: '/wxpublic/verifyWXToken',
    method: 'post',
    data
  })
}
//导出微信小店订单
export function exportWxxdList(data) {
  return request({
    url: '/trade/exportWxxdList',
    method: 'post',
    data
  })
}

//导出京东订单
export function exportJdList(data) {
  return request({
    url: '/trade/exportJdList',
    method: 'post',
    data
  })
}

//导出天猫订单
export function exportTbList(data) {
  return request({
    url: '/trade/exportTbList',
    method: 'post',
    data
  })
}

//录入订单选择
export function anchorIn(data) {
  return request({
    url: '/trade/anchorIn',
    method: 'post',
    data
  })
}

//录入订单列表
export function anchorInData(data) {
  return request({
    url: '/trade/anchorInData',
    method: 'post',
    data
  })
}


//删除签到活动用户
export function delSignature(data) {
  return request({
    url: '/signature/delSignature',
    method: 'post',
    data
  })
}


//签到活动列表
export function querySignature(data) {
  return request({
    url: '/signature/querySignature',
    method: 'post',
    data
  })
}

//签到活动导出
export function queryExSignature(data) {
  return request({
    url: '/signature/queryExSignature',
    method: 'post',
    data
  })
}

//年会人员删除
export function delWxhSignature(data) {
  return request({
    url: '/signature/delWxhSignature',
    method: 'post',
    data
  })
}

//年会人员核销
export function updateWxhSignature(data) {
  return request({
    url: '/signature/updateWxhSignature',
    method: 'post',
    data
  })
}

//年会人员查询
export function queryWxhSignature(data) {
  return request({
    url: '/signature/queryWxhSignature',
    method: 'post',
    data
  })
}

//年会人员导出
export function queryWxhExSignature(data) {
  return request({
    url: '/signature/queryWxhExSignature',
    method: 'post',
    data
  })
}

//玫瑰人员
export function queryBxMeigui(data) {
  return request({
    url: '/signature/queryBxMeigui',
    method: 'post',
    data
  })
}